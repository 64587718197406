import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'



const initialState = {

    loading: false,
    user: [],
    error: '',
    success: false,
    status: 0,
    loggedIn: false
}


export const signinData  = createAsyncThunk('user/signinData', async (objdata, { rejectWithValue }) => {
        const {url, loginInfo, config} = objdata;
        try {
          const result = await axios.post(url, loginInfo, {headers: config})
          return result.data
        }catch(err){
          return rejectWithValue({status: err.response?.status, data: err.response?.data?.error || err.response?.data, text: err.message})
        }
            })  
const signinSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      cook: (state, action) => {
        state.user =  action.payload
        state.loggedIn = true
      },
      refresh: (state, action) => {
        state.user.tokens.access = action.payload.access
        
        state.user.tokens.refresh = action.payload.refresh
        
      },
      errorClear: (state, action) => {
          state.error = ""
      },
      successClear: (state, action) => {
        state.success = false
      }
    },
    extraReducers: builder => {
          builder.addCase(signinData.pending, state => {
            state.loading = true
            state.error=""
            state.success = false
          })
          builder.addCase(signinData.fulfilled, (state, action) => {
            state.loading = false
            state.user = action.payload
            state.error = ''
            state.success = true
            state.status = 200
          })
          builder.addCase(signinData.rejected, (state, action) => {
            state.loading = false
            state.user = {}
            state.success = false
            console.log(action.payload.status);
            state.status= action.payload.status
            state.error = action.error.message
          });
         
    }
});


export default signinSlice.reducer
export const { cook, refresh, errorClear, successClear } = signinSlice.actions