import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'



const initialState = {

    loading: false,
    data: [],
    error: '',
    status: '',
    success: false,
    alert: false,
    alertMessage: "",
    alertColor: ""
}


export const postData  = createAsyncThunk('data/postData', async (objdata, { rejectWithValue }) => {
  const {url, data, config} = objdata;
  try {
    const result = await axios.post(url, data, {headers: config})
    return result.data
  }catch(err){
    return rejectWithValue({status: err.response?.status, data: err.response?.data?.error || err.response?.data, text: err.message})
  }
})
const postSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
      setAlert: (state, action) => {
        state.alert = action.payload.alert
        state.alertMessage = action.payload.message
        state.alertColor = action.payload.color
      },
      clear: (state, action) => {
          state.success = false
      },
      errorClear: (state, action) => {
          state.error = ""
      },
      successClear: (state, action) => {
        state.success = false
      }
    },
    extraReducers: builder => {
          builder.addCase(postData.pending, state => {
            state.loading = true
            state.error= ''
            state.success = false
          })
          builder.addCase(postData.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
            state.success = true
          })
          builder.addCase(postData.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.payload.data
            state.status = action.payload.status
            state.success = false
          });
    },
});


export default postSlice.reducer
export const { setAlert, clear, errorClear, successClear } = postSlice.actions